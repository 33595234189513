import React, {Fragment, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Typography from '@mui/material/Typography';
import { useMutation } from "react-query";
import apiClient from "../../request/http-common";
import { setInvestments } from '../../request/Analysis/hitorySlice';
import { useDispatch } from 'react-redux';

const TradeTimer = (props) => {
    const {time, trade} = props;
    const countDownDate = new Date(time * 1000);
    const [text, setText] = useState('');
    const [error, setErros] = useState('');

    const [percentage, setPercentage] = useState(0);
    const [profit, setProfit] = useState(0);
    const dispatch = useDispatch();

    function randomNumber(min, max, type) {

      switch (type) {
        case 'profit':
          let minPercent = min/100;
          let maxPercent = max/100;
          return Math.random() * (maxPercent - minPercent) + minPercent;
          break;

        case 'loss':
          let minLoss = -min/100;
          let maxLoss = -max/100;
          return Math.random() * (maxLoss - minLoss) + minLoss;
          break;

        case 'profit_loss':
          let minProfitLoass = -min/100;
          let maxProfitLoss = max/100;
          return Math.random() * (maxProfitLoss - minProfitLoass) + minProfitLoass;
          break;

        default:
          return Math.random() * (max - min) + min;
          break;
      }

      
  }


  const { isLoading: isClosing, mutate: closeTrade } = useMutation(
    async () => {
      return await apiClient.post(`/api/close-trade`, {
        trading_id: trade.id,
        profit: (trade.amount * percentage), 
        percentage: percentage
      });
    },
    {
      onSuccess: (res) => {
          if (res.data.status === 'success') {
            dispatch(setInvestments(res.data.tradings));
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
           
          }            
      },
      onError: (err) => {
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }
      },
    }
  );

    useEffect(() => {
        var timer = setInterval(function() {

          if (trade !== undefined &&  trade.status === 'open') {
          
            var now = new Date().getTime();
            var distance = countDownDate - now;
          
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    
            if (distance < 0) {
              clearInterval(timer);
              
              if (trade !== undefined) {
                if (trade.status === 'open') {
                  setPercentage(randomNumber(trade.plan.min_range, trade.plan.max_range, trade.plan.final_result))
                  closeTrade()
                }
              }
              setText('closed')
            }else{
                setText(`${hours}h ${minutes}m ${seconds}s`)
                if (trade !== undefined) {
                  setPercentage(randomNumber(trade.plan.min_range, trade.plan.max_range, trade.plan.final_result))

                  let currentProfit = trade.amount * percentage;
                  if (trade.stop_loss !== null && currentProfit < 1) {
                    if (-parseFloat(trade.stop_loss) >= currentProfit) {
                      closeTrade();
                    }
                  }

                  if (trade.take_profit !== null && currentProfit > 0) {
                    if (parseFloat(trade.take_profit) <= currentProfit) {
                      closeTrade();
                    }
                  }
                  
                }

            }
          }
          }, 1000);

        return () => {
            clearInterval(timer)
        };
    }, [time]);

    // Update the count down every 1 second
    

  return (   
    <Fragment>
    {trade !== undefined &&  
    <Box  sx={{display: 'flex', flexDirection: 'column'}}>
      {trade.status === 'open'? 
      <Fragment>      
      <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', alignContent: 'center'}}>
          <AccessTimeIcon /> 
          <Typography py={1} variant="caption">{isClosing? 'closing...' : text}</Typography>
      </Box>
      {percentage > 0 ?
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography sx={{color: '#19ff01', fontWeight: 'bolder'}}>
            
            ${(trade.amount * percentage).toFixed(4)} 
            
            </Typography> 
          <Typography sx={{color: '#19ff01', fontWeight: 'bolder', marginLeft: '8px'}}>{percentage.toFixed(4)}%</Typography>
        </Box>
        :
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography sx={{color: '#ff1414', fontWeight: 'bolder'}}>${(trade.amount * percentage).toFixed(4)} </Typography>
          <Typography sx={{color: '#ff1414', fontWeight: 'bolder', marginLeft: '8px'}}>{percentage.toFixed(4)}%</Typography>
        </Box>
        }
        </Fragment>
        :

        <Fragment>      
      <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', alignContent: 'center'}}>
          <AccessTimeIcon /> 
          <Typography py={1} variant="caption">{isClosing? 'closing...' : text}</Typography>
      </Box>

      {parseFloat(trade.percentage) > 0 ?
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography sx={{color: '#19ff01', fontWeight: 'bolder'}}>
            ${parseFloat(trade.profit).toFixed(4)} 
            </Typography> 
          <Typography sx={{color: '#19ff01', fontWeight: 'bolder', marginLeft: '8px'}}>{parseFloat(trade.percentage).toFixed(4)}%</Typography>
        </Box>
        :
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography sx={{color: '#ff1414', fontWeight: 'bolder'}}>${parseFloat(trade.profit).toFixed(4)} </Typography>
          <Typography sx={{color: '#ff1414', fontWeight: 'bolder', marginLeft: '8px'}}>{parseFloat(trade.percentage).toFixed(4)}%</Typography>
        </Box>
        }
        </Fragment>
      }
    </Box>
}
      </Fragment>
  )
}

export default TradeTimer
