import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';

const KycVerification = () => {
  
      
    const dispatch = useDispatch();
    
    const initialLogin = {
        first_name : ''	,
        last_name : ''	,
        phone_number : ''	,
        date_of_birth : ''	,
        residential_address: '',
        document_type : ''	,
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });

    const initialImage = {
        image: ''
    }
    const [FrontImage, setImage] = useState(initialImage);
    const  onFileChange = (event) => { 
        setImage({...FrontImage, image: event.target.files[0]});
      }; 


      const initialbackImage = {
        image: ''
    }
    const [backImage, setbackImage] = useState(initialbackImage);
    const  onBackFileChange = (event) => { 
        setbackImage({...backImage, image: event.target.files[0]});
      }; 

      const initialHandImage = {
        image: ''
    }
    const [handImage, setHandImage] = useState(initialHandImage);
    const  onHandFileChange = (event) => { 
        setHandImage({...handImage, image: event.target.files[0]});
      }; 

      

    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
            const formData = new FormData();
            formData.append('first_name' , planForm.first_name)
            formData.append('document_type' , planForm.document_type)
            formData.append('last_name' , planForm.last_name)
            formData.append('phone_number' , planForm.phone_number)
            formData.append('date_of_birth' , planForm.date_of_birth)
            formData.append('residential_address', planForm.residential_address)
            formData.append('front_document', FrontImage.image);
            formData.append('back_document', backImage.image);
            formData.append('hand_document', handImage.image);

          return await apiClient.post(`/api/kyc-verification`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
          },
          onError: (err) => {   
            
            if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
            setErros(myerror.errors)     
            const event2 = new Event('processed');
            window.dispatchEvent(event2);          
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
            window.dispatchEvent(event2);
            }   
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 


  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        VERIFY YOUR KYC 
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to verify your KYC, the most common documents used for proof of identity are government-issued identification documents like a passport, driver's license, or national ID card
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="document_type">Document Type</InputLabel>
                      {(error !== '' && error.document_type) ?
                          <Select
                              error
                              onChange={onChange}
                              labelId="document_type"
                              id="document_type"
                              name="document_type"
                              label={`Document Type`}
                              helperText={error.document_type}
                              >
                              <MenuItem selected value=''>
                                  Select document type
                              </MenuItem>

                              <MenuItem selected value='passport'>
                              passport
                              </MenuItem> 
                              <MenuItem selected value="driver's license">
                              driver's license
                              </MenuItem> 
                              <MenuItem selected value='National ID Card'>
                              National ID Card
                              </MenuItem>              
                              
                          </Select>
                      :
                          <Select
                              onChange={onChange}
                              labelId="document_type"
                              name="document_type"
                              id="document_type"
                              label={`Document Type`}
                              >
                              <MenuItem selected value="">
                                  Select Document Type
                              </MenuItem>     

                              <MenuItem selected value='passport'>
                              Passport
                              </MenuItem> 
                              <MenuItem selected value="driver's license">
                              driver's license
                              </MenuItem> 
                              <MenuItem selected value='National ID Card'>
                              National ID Card
                              </MenuItem>    
                          </Select>
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.document_type}
                      </Typography>
                  </FormControl>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="First Name">First Name</InputLabel>
                            {(error !== '' && error.first_name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="first_name"
                            name="first_name"
                            type={'text'}
                            label="First Name"
                            helperText={error.first_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="first_name"
                            name="first_name"
                            type={'text'}
                            label="First Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.first_name}
                            </Typography>
                            
                        </FormControl>

                        
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="last_name">Last Name</InputLabel>
                            {(error !== '' && error.last_name) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="last_name"
                            type={'text'}
                            name="last_name"
                            label="Last Name"
                            helperText={error.last_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="last_name"
                            type={'text'}
                            name="last_name"
                            label="Last Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.last_name}
                            </Typography>
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="phone_number">Phone</InputLabel>
                            {(error !== '' && error.phone_number) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="phone_number"
                            type={'tel'}
                            name="phone_number"
                            label="Phone"
                            helperText={error.phone_number}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="phone_number"
                            type={'tel'}
                            name="phone_number"
                            label="Phone"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.phone_number}
                            </Typography>
                            
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="residential_address">Residential Address</InputLabel>
                          {(error !== '' && error.residential_address) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="residential_address"
                          type={'text'}
                          name="residential_address"
                          label="Residential Address"
                          helperText={error.residential_address}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="residential_address"
                          type={'text'}
                          name="residential_address"
                          label="Residential Address"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.residential_address}
                          </Typography>
                      </FormControl>
{/* 
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="document_type">Document Type</InputLabel>
                            {(error !== '' && error.document_type) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="document_type"
                            type={'text'}
                            name="document_type"
                            label="Document Type"
                            helperText={error.document_type}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="document_type"
                            type={'text'}
                            name="document_type"
                            label="Document Type"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.document_type}
                            </Typography>
                            
                        </FormControl> */}

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="date_of_birth">Date Of Birth</InputLabel>
                            {(error !== '' && error.date_of_birth) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="date_of_birth"
                            type={'date'}
                            name="date_of_birth"
                            label="Date Of Birth"
                            helperText={error.date_of_birth}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="date_of_birth"
                            type={'date'}
                            name="date_of_birth"
                            label="Date Of Birth"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.date_of_birth}
                            </Typography>
                            
                        </FormControl>

                        

                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        
                      
                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Document Front View
                        </Typography>
                        <Box>
                        {FrontImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(FrontImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.front_document) ?

                            <OutlinedInput
                            onChange={onFileChange}
                            error
                            accept="image/*"
                            id="front_document"
                            type='file'
                            name="front_document"
                            helperText={error.front_document}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onFileChange}
                            id="front_document"
                            type='file'
                            name="front_document"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.front_document}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Document Back View
                        </Typography>
                        <Box>
                        {backImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(backImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.back_document) ?

                            <OutlinedInput
                            onChange={onBackFileChange}
                            error
                            accept="image/*"
                            id="back_document"
                            type='file'
                            name="back_document"
                            helperText={error.back_document}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onBackFileChange}
                            id="back_document"
                            type='file'
                            name="back_document"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.back_document}
                            </Typography>
                        </FormControl>
                        </Box>
                        
                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Hand Holding ID
                        </Typography>
                        <Box>
                        {handImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(handImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.hand_document) ?

                            <OutlinedInput
                            onChange={onHandFileChange}
                            error
                            accept="image/*"
                            id="hand_document"
                            type='file'
                            name="hand_document"
                            helperText={error.hand_document}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onHandFileChange}
                            id="hand_document"
                            type='file'
                            name="hand_document"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.hand_document}
                            </Typography>
                        </FormControl>
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Submitting...' : 'Verify KYC'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default KycVerification
