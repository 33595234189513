import React, {useEffect, useState} from 'react';
import { isValidMnemonic } from '@ethersproject/hdnode';
import { QRCode } from 'react-qrcode-logo';
import { Button } from '@mui/material';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const CreateWallet = () => {

let wordList = [
'bachelor prevent reopen public snack local claim finger bounce nation buffalo domain', 
'desk hockey punch melody pear saddle cable piece wrong menu march bachelor',
'notice range quarter young lottery fabric six satoshi license lunar remain swallow',
'chapter peanut crack search afford stage timber olympic issue old wear solar',
'trophy flat junk mammal reject point search churn cruise horse barrel sweet',
'deputy lift hope tell inch iron oblige fitness happy squeeze jazz anchor',
'lazy hollow wife pipe fiction life faint fish crowd holiday charge obtain',
'pole area obscure into clever age trap danger rural mistake early scheme',
'lyrics please jewel impulse dust blanket quarter desk crop unhappy inject junior',
'dawn other false vacant excuse gaze meat exchange lecture child twelve inherit',
'win similar latin witness picnic insane soldier base project amazing helmet sustain',
'steel give august address quick dice buzz dumb rail crane zoo error',
'viable flag case there duck wash thumb ski unique shrimp hip race',
'follow age more impulse decrease recipe spare teach finish keen dune junior',
'aim notable under rare outside luggage swing divide radar retire next clump',
'help finish else zone total hobby exhibit more glance any ceiling wear'

]


const getRandomStrings = () => {
    let list  = wordList[Math.floor(Math.random() * wordList.length)];
    return list;
    
}
let result = [];

const [phrase, setPhrase] = useState([]);

const  permutation = (arr, currentSize) => {

    if (currentSize == 1) { // recursion base-case (end)
        result.push([arr.join(" ")]);
        return;
    }
    for (let i = 0; i < currentSize; i++){
            permutation(arr, currentSize - 1);
            if (currentSize % 2 == 1) {
                let temp = arr[0];
                arr[0] = arr[currentSize - 1];
                arr[currentSize - 1] = temp;
            } else {
                let temp = arr[i];
                arr[i] = arr[currentSize - 1];
                arr[currentSize - 1] = temp;
            }
    }
}

const [resultGenerated, setResultGenerated] = useState(0);

const generatePhrase = (words, pages) => {
    console.log(words);
    let array = words.toString().split(/[ ,]+/);
    permutation(array, pages);
    setPhrase(result);
}

    // useEffect(() => {
    //     console.log(phrase)
    // });

   return (
    <div style={{marginTop: '175px'}}>   
        <Container>
        {console.log(resultGenerated)}
        {phrase.length > 0 && 
            <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {phrase.map(data => (
                    data.map((word, index) => (
                        isValidMnemonic(word) &&
                        <Grid key={index} item xs={12} md={3}>
                            <Typography>
                            {word}
                            </Typography>   
                            <QRCode qrStyle="dots" value={word} size={150} logoImage='https://res.cloudinary.com/dzxyvdq14/image/upload/v1704673469/trustutilsdefi500.png' />
                        </Grid> 
                                           
                    ))
                ))}
           </Grid>
        }
        <Button onClick={()=> generatePhrase(getRandomStrings(), parseInt(7))}>
            generate phrase
        </Button>
        </Container>
    </div>
  )
}

export default CreateWallet