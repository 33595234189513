import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    withdrawals: JSON.parse(localStorage.getItem('withdrawals')) || [],
    wallets: JSON.parse(localStorage.getItem('wallets')) || [],
    investments: JSON.parse(localStorage.getItem('investments')) || [],
    depayTransactions: JSON.parse(localStorage.getItem('depayTransactions')) || [],
    loans:  JSON.parse(localStorage.getItem('loans')) || []
}

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setWithdrawals: (state, action) =>{
            state.withdrawals = action.payload;  
            localStorage.setItem('withdrawals', JSON.stringify(state.withdrawals));
        },

        setWallets: (state, action) =>{
            state.wallets = action.payload;  
            localStorage.setItem('wallets', JSON.stringify(state.wallets));
        },

        setInvestments: (state, action) =>{
            state.investments = action.payload;  
            localStorage.setItem('investments', JSON.stringify(state.investments));
        },

        setLoans: (state, action) =>{
            state.loans = action.payload;  
            localStorage.setItem('loans', JSON.stringify(state.loans));
        },

        setDepayTransactions: (state, action) =>{
            state.depayTransactions = action.payload;  
            localStorage.setItem('depayTransactions', JSON.stringify(state.depayTransactions));
        },


    }
})

export const {setWithdrawals, setWallets, setInvestments, setDepayTransactions, setLoans} = historySlice.actions;
export default historySlice.reducer;