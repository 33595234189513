import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

export default function ListUsers() {
    const [users, setUsers] = useState(null);
    const { isLoading: isLoadingusers, refetch: getusers } = useQuery(
        "load-users",
        async () => {
          return await apiClient.get("/api/users");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setUsers(res.data.users);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getusers() 
        if (isLoadingusers) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadingusers? 'Loading Users...' : 'List of users'}
      </Typography>
    <TableContainer component={Paper}>
        
      {users !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        <TableCell>Address</TableCell>
          <TableCell align="right">View</TableCell>
          <TableCell align="right">Profile</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => (
          <TableRow
            key={user.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {user.address}
            </TableCell>
            <TableCell align="right"><Button component={RouterLink} to={`/user-dashboard/${user.id}`} startIcon={<EditIcon />}>View User</Button></TableCell>
            <TableCell align="right"><Avatar alt={user.name} src={user.profile} /></TableCell>
            <TableCell align="right">{getDate(user.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}