import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AddLinkIcon from '@mui/icons-material/AddLink';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import { CardActions, CardContent } from '@mui/material';
import { Button } from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TradingButton from '../components/Trader/TradingButton';
export default function Footer() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const location = useLocation();
  const { pathname } = location;

  

  return (
    <Box sx={{ display: {md: 'none'},  pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 200 }} elevation={3}>
        {pathname === '/trade' ? 
         <TradingButton/>
        : 
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={RouterLink} to="/transactions" label="Transactions" icon={<TimelineIcon />} />
          <BottomNavigationAction component={RouterLink} to="/swap-coin" label="Swap" icon={<SwapHorizIcon />} />
          <BottomNavigationAction component={RouterLink} to="/wallet" label="Wallet" icon={<AccountBalanceWalletIcon />} />
          <BottomNavigationAction component={RouterLink} to="/" label="New Wallet" icon={<AddLinkIcon/>} />
          
          
          {/* <BottomNavigationAction label="Our Menu" icon={<MenuBookIcon />}/> */}
          
        </BottomNavigation>
        }
        
      </Paper>

    </Box>
  );
}
